import * as React from 'react';

import { useCheckoutContext } from 'modules/PricingPage';
import {
  MembershipHeader, MembershipCard,
} from 'modules/PricingPage/PricingOverview/components';
import { useGetPromoContent } from 'modules/contentBlocks/PromoBlock/useGetPromoContent';

import { MobileBenefitItem } from './MobileBenefitItem';
import {
  MobilePricingOverviewContainer,
  MembershipSelectContainer,
  MembershipBenefitsContainer,
  MobileBenefitContainer,
  MobileBenefitTitle,
  MobileBenefitItemContainer,
} from './styled';

export const MobileMembershipDetails = ({ isCollapsable, showAllBenefits, isUpgrade }: MobileMembershipDetailsProps) => {
  const membershipHeaderRef = React.useRef<HTMLDivElement>(null);
  const membershipMobileOverviewRef = React.useRef<HTMLDivElement>(null);
  const [headerIsFixed, setHeaderIsFixed] = React.useState(false);
  const [offsetTop, setOffsetTop] = React.useState<number>();
  const [overviewBottom, setOverviewBottom] = React.useState<number>();
  const {
    localizedMemberships, benefits, selectedMembership, setSelectedMembership, benefitsShortList,
    setSelectedMembershipType,
  } = useCheckoutContext();
  const { promotionContent } = useGetPromoContent();
  const benefitsFormatted = isCollapsable
    ? showAllBenefits ? benefits : benefitsShortList
    : benefits;

  React.useEffect(() => {
    if (isUpgrade) {
      setSelectedMembershipType('upgrade');
    }
  }, [isUpgrade]);

  const handleScroll = () => {
    if (offsetTop && overviewBottom) {
      const position = window.scrollY;
      setHeaderIsFixed(position > offsetTop && position < (offsetTop + overviewBottom));
    }
  };

  React.useEffect(() => {
    // 80 is padding on top of the fixed membership header to avoid the menu bar icons
    if (membershipHeaderRef?.current) setOffsetTop(membershipHeaderRef.current.offsetTop - 80);

    // promotionContent Needs to be here because offset can be different based on this
  }, [membershipHeaderRef, promotionContent]);

  React.useEffect(() => {
    // Find the height of component for the bottom value to unstick the header
    if (membershipMobileOverviewRef?.current) setOverviewBottom(membershipMobileOverviewRef.current.clientHeight);

    // showAllBenefits and promotionContent need to be here
    // because the height needs to be refetched after expansion
  }, [membershipMobileOverviewRef, promotionContent, showAllBenefits]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [offsetTop, overviewBottom]);

  return (
    <MobilePricingOverviewContainer ref={membershipMobileOverviewRef}>
      <MembershipSelectContainer
        ref={membershipHeaderRef}
        headerIsFixed={headerIsFixed}
      >
        {localizedMemberships.map((membership) => {
          const isSelected = selectedMembership?.id === membership.id;

          return (
            <MembershipCard
              key={membership.id}
              isSelected={selectedMembership?.id === membership.id}
              onClick={!isUpgrade || isSelected ? () => setSelectedMembership(membership) : undefined}
            >
              <MembershipHeader membership={membership} />
            </MembershipCard>
          );
        })}
      </MembershipSelectContainer>

      <MembershipBenefitsContainer
        headerIsFixed={headerIsFixed}
        headerHeight={(membershipHeaderRef?.current?.offsetHeight || 0)}
      >
        {benefitsFormatted.map((benefit) => (
          <MobileBenefitContainer key={benefit.id}>
            <MobileBenefitTitle>{benefit.longDescription}</MobileBenefitTitle>
            <MobileBenefitItemContainer key={benefit.id}>
              {localizedMemberships.map((membership, index) => (
                <MobileBenefitItem key={benefit.id + index} {...{ benefit, membership }} />
              ))}
            </MobileBenefitItemContainer>
          </MobileBenefitContainer>
        ))}
      </MembershipBenefitsContainer>
    </MobilePricingOverviewContainer>
  );
};

type MobileMembershipDetailsProps = {
  isCollapsable?: boolean;
  showAllBenefits?: boolean;
  isUpgrade?: boolean;
};
