import React from 'react';

import { Text } from 'common/typography';

import { PricingCardContainer, LabelContainer } from './styled';

export const PricingCard: React.FC<PricingCardProps> = ({ children, name }) => {
  const label = name.substring(name.indexOf('[') + 1, name.lastIndexOf(']'));
  const hasLabel = Boolean(label);

  return (
    <PricingCardContainer {...{ hasLabel }}>
      {hasLabel && (
        <LabelContainer>
          <Text
            variant="white"
            $uppercase
            $size={12}
          >
            {label}
          </Text>
        </LabelContainer>
      )}
      {children}
    </PricingCardContainer>
  );
};

type PricingCardProps = {
  name: string;
};
