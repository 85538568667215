import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const PricingCardWrapper = styled.div<PricingCardWrapperProps>`
  ${media.desktop<PricingCardWrapperProps>`
    display: grid;
    grid-column: 3/9;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;

    ${({ isContentBlock }) => isContentBlock && css`
      grid-column: unset;
    `};

    ${({ isCentered }) => isCentered && css`
      display: flex;
      justify-content: center;
    `};
  `}
`;

type PricingCardWrapperProps = {
  isCentered?: boolean;
  isContentBlock?: boolean;
};
