import styled from 'styled-components';

import { media, CONTAINER_WIDTH_DESKTOP } from 'styles/utils';

export const BannerContainer = styled.div<BannerContainerProps>`
  background: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-position: center;
  margin-bottom: 60px;

  ${media.desktop`
    margin-right: calc((100vw - ${CONTAINER_WIDTH_DESKTOP}px) / 2);
    margin-bottom: 120px;
    height: 552px;
  `}
`;

type BannerContainerProps = {
  imageUrl: string;
};

export const BannerContent = styled.div`
  padding: 44px;

  ${media.desktop`
    padding: 88px 0 0 156px;
    max-width: 600px;
  `}
`;
