import * as i from 'types';
import React from 'react';
import { graphql } from 'gatsby';

import { ContentContainer } from 'common/layout';
import { Heading } from 'common/typography';
import { Accordion, AccordionItemsType } from 'common/interaction';

export const AccordionBlock: React.FC<AccordionBlockProps> = ({ content }) => {
  const accordionItems: AccordionItemsType = [];
  [...new Array(10)].forEach((_, index) => {
    const key = index + 1;
    //@ts-ignore - ts doens't get the object nesting with matching keys
    const itemText = content[`accordionText${key}`]?.[`accordionText${key}`];
    const itemTitle = content[`accordionTitle${key}` as keyof i.ComponentAccordionBlock];
    if (itemTitle) {
      accordionItems.push({
        title: itemTitle as string,
        text: itemText,
      });
    }
  });

  return (
    <ContentContainer>
      <Heading as="h2" variant="black">{content.title}</Heading>
      <Accordion items={accordionItems} />
    </ContentContainer>
  );
};

export const query = graphql`
  fragment AccordionFragment on ContentfulComponentAccordion {
    title
    accordionTitle1
    accordionTitle2
    accordionTitle3
    accordionTitle4
    accordionTitle5
    accordionTitle6
    accordionTitle7
    accordionTitle8
    accordionTitle9
    accordionTitle10
    accordionText1 { accordionText1 }
    accordionText2 { accordionText2 }
    accordionText3 { accordionText3 }
    accordionText4 { accordionText4 }
    accordionText5 { accordionText5 }
    accordionText6 { accordionText6 }
    accordionText7 { accordionText7 }
    accordionText8 { accordionText8 }
    accordionText9 { accordionText9 }
    accordionText10 { accordionText10 }
  }
`;

type AccordionBlockProps = {
  content: i.ComponentAccordionBlock;
};
