import * as i from 'types';
import * as React from 'react';
import { useLocation } from '@reach/router';

import { seriesLoading } from 'selectors/classes';
import { useGetMemberships, useSelector, useAuthenticationUser, useDispatch } from 'services/hooks';
import { getSeries } from 'ducks/series';
import { getLocalData } from 'services';
import { getSites, sitesActions } from 'ducks/sites';
import { selectActiveSiteData } from 'selectors/sites';

export const CheckoutContext = React.createContext<CheckoutContextProps>(
  {} as CheckoutContextProps,
);

export const useCheckoutContext = () => {
  const context = React.useContext(CheckoutContext);
  if (!context) throw new Error ('CheckoutContext cannot be rendered outside of the provider');

  return context;
};

export const CheckoutProvider: React.FC<CheckoutProviderProps> = ({ children, filteredMemberships, defaultMembershipIdFromBlock }) => {
  const [activeItem, setActiveItem] = React.useState<i.Serie | undefined>(undefined);
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const [currentStep, setCurrentStep] = React.useState(1);
  const [selectedMembership, setSelectedMembership] = React.useState<i.ComponentMembershipBlock>();
  const [selectedMembershipType, setSelectedMembershipType] = React.useState<MembershipCheckoutTypes>('annually');
  const location = useLocation();
  const { localizedMemberships, benefits } = useGetMemberships({
    filterIds: filteredMemberships,
    isForPricingPage: location.pathname.includes('pricing'),
  });

  const dispatch = useDispatch();
  const { determined } = useAuthenticationUser();
  const activeSite = useSelector(selectActiveSiteData);

  const selectedSerie = selectedMembershipType === 'annually' ? selectedMembership?.annualSerie
    : selectedMembershipType === 'upgrade' ? selectedMembership?.upgradeSerie
      : selectedMembership?.flexSerie;
  const isLoading = useSelector(seriesLoading);

  const fetchPricingData = async () => {
    if (!activeSite) {
      const localSite = getLocalData('user::activeSite');
      const sites = await dispatch(getSites());
      dispatch(sitesActions.setActiveSite(localSite || sites[0]?.city));
    } else {
      dispatch(getSeries(activeSite));
    }
  };

  React.useEffect(() => {
    // ToDo: Create fix for all these determined loading effects
    if (determined) fetchPricingData();
  }, [determined, activeSite]);

  const handleOpenModal = (item?: i.Serie) => {
    setModalOpen(true);
    setActiveItem(item);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setActiveItem(undefined);
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  React.useEffect(() => {
    if (!isLoading) {
      const defaultMembership = localizedMemberships.find((membership) =>
        // defaultMembershipIdFromBlock means a default id from a contentBlock instead of overall default membership from pricing page
        defaultMembershipIdFromBlock ? membership.id === defaultMembershipIdFromBlock : membership.isDefault,
      );
      setSelectedMembership(defaultMembership);
    }
  }, [isLoading]);

  const methods = {
    activeItem,
    isModalOpen,
    handleOpenModal,
    handleCloseModal,
    currentStep,
    setCurrentStep,
    handleNextStep,
    handlePreviousStep,
    localizedMemberships,
    benefits,
    benefitsShortList: benefits.slice(0, 5),
    selectedMembershipType,
    setSelectedMembershipType,
    selectedMembership,
    setSelectedMembership,
    selectedSerie,
  };

  return (
    <CheckoutContext.Provider value={methods}>
      {children}
    </CheckoutContext.Provider>
  );
};

type CheckoutContextProps = {
  activeItem?: i.Serie;
  isModalOpen: boolean;
  handleCloseModal: () => void;
  handleOpenModal: (item?: i.Serie) => void;
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
  localizedMemberships: i.ComponentMembershipBlock[];
  benefits: i.Benefit[];
  benefitsShortList: i.Benefit[];
  selectedMembershipType: MembershipCheckoutTypes;
  setSelectedMembershipType: (selectedMembershipType: MembershipCheckoutTypes) => void;
  selectedMembership?: i.ComponentMembershipBlock;
  setSelectedMembership: (membership: i.ComponentMembershipBlock) => void;
  selectedSerie?: i.Serie;
};

type CheckoutProviderProps = {
  filteredMemberships?: string[];
  defaultMembershipIdFromBlock?: string;
};

type MembershipCheckoutTypes = 'annually' | 'flex' | 'upgrade';
