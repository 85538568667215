import styled, { css } from 'styled-components';

import { Button } from 'common/interaction';

import { media } from 'styles/utils';

export const StyledButton = styled(Button)`
  max-width: 264px;
`;

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ $flexEnd }) => $flexEnd ? 'flex-end' : 'flex-start'};
`;

type ColumnProps = {
  $flexEnd?: boolean;
};

export const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  ${media.desktop`
    grid-template-columns: 1fr 1fr;
  `}
`;

export const FormBlockContainer = styled.div`
  padding: 40px 0 0;
  position: relative;
  margin: 0 0 60px;

  ${media.tablet`
    display: flex;
    padding: 0;
    justify-content: flex-end;
    margin: 0 0 120px;
  `}

  ${media.desktop`
    padding: 55px 0 0;
  `}
`;

export const TitleContainer = styled.div<ContainerProps>`
  background: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 24px;
  right: 24px;
  height: 200px;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 56px 65px;

  ${media.tablet`
    top: 0;
    left: 0;
    width: 30%;
    bottom: 0;
    height: auto;
  `}

  ${media.desktop`
    height: 443px;
    width: 30%;
  `}
`;

type ContainerProps = {
  src?: string;
};

export const FormContent = styled.div`
  button {
    margin: 0 auto;
  }

  ${media.tablet`
    button {
      margin: 0;
    }
  `}
`;

export const FormContentContainer = styled.div`
  background: ${({ theme }) => theme.colors.gray.light};
  padding: 190px 24px 24px;

  ${media.tablet`
    padding: 20px 40px 40px;
    width: 70%;
  `}

  ${media.desktop`
    height: 483px;
    padding: 80px 15% 0;
    width: 77%;
  `}
`;
