import React from 'react';

import { PromotionClass } from 'modules/PricingPage';
import { configureImageSrc } from 'services';

import { useGetPromoContent } from './useGetPromoContent';
import { PromoBlockContainer } from './styled';

export const PromoBlock = () => {
  const { promotionContent, userType } = useGetPromoContent();

  if (!promotionContent) return null;

  return (
    <PromoBlockContainer hidePromoBlock={userType !== 'first-timer'}>
      <PromotionClass
        title={promotionContent.title}
        subTitle={promotionContent.subtitle}
        tagLabel={promotionContent.label}
        serie={promotionContent.serie}
        backgroundImg={configureImageSrc(promotionContent.image?.file?.url, 1000)}
      />
    </PromoBlockContainer>
  );
};

