import styled, { css } from 'styled-components';

export const AlignmentContainer = styled.div<AlignmentContainerProps>`
  display: flex;
  max-width: 744px;
  margin: 0 auto;

  ${({ $align }) => $align === 'left' && css`
    justify-content: flex-start;
  `}

  ${({ $align }) => $align === 'center' && css`
    justify-content: center;

    h1, h2, h3, h4 {
      text-align: center;
    }
  `}

  ${({ $align }) => $align === 'right' && css`
    justify-content: flex-end;
  `}

`;

type AlignmentContainerProps = {
  $align: 'left' | 'center' | 'right';
};
