import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Heading, Text } from 'common/typography';
import { PersonalTrainer } from 'modules/PricingPage';

import { Layout, HeaderContainer, PromoWrapper } from './styled';

export const PersonalTrainingBlock = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <HeaderContainer>
        <Heading as="h2" variant="black" margin="0" upperCase>{t('personal trainer')}</Heading>
        <Text variant="gray" $lineHeight="24">{t('unlimited gold')}</Text>
      </HeaderContainer>
      <PromoWrapper>
        <PersonalTrainer />
      </PromoWrapper>
    </Layout>
  );
};
