import * as React from 'react';

import { selectActiveSiteData } from 'selectors/sites';
import { useDevice, useDispatch, useSelector } from 'services/hooks';
import { useCheckoutContext, PricingTable, CheckoutForm } from 'modules/PricingPage';
import { createOrder, getPaymentInfo } from 'ducks/orders';
import { Modal } from 'common/layout';

import {
  CheckoutContainer, CheckoutHeader, CloseIcon, ContentContainer,
  CheckoutHeading, StyledArrow,
} from './styled';

export const CheckoutModal = () => {
  const dispatch = useDispatch();
  const activeSite = useSelector(selectActiveSiteData);
  const { isDesktop } = useDevice();
  const mobileVariant = {
    hidden: {
      y: '100%',
    },
    visible: {
      y: '0%',
    },
  };

  const desktopVariant = {
    hidden: {
      x: '100%',
    },
    visible: {
      x: '75%',
    },
  };

  const {
    isModalOpen, handleCloseModal, activeItem, currentStep, handlePreviousStep,
  } = useCheckoutContext();
  const animationVariant = isDesktop ? desktopVariant : mobileVariant;
  const activeItemName = activeItem?.name.split('[')[0];

  React.useEffect(() => {
    if (!activeItem) return;
    dispatch(createOrder(activeItem?.id))
      .then((order) => {
        dispatch(getPaymentInfo(order.id));
      });
  }, [activeItem]);

  return (
    <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
      <CheckoutContainer
        animate={isModalOpen ? 'visible' : 'hidden'}
        initial="hidden"
        variants={animationVariant}
        key="checkoutContainer"
      >
        <CheckoutHeader>
          <CloseIcon onClick={() => handleCloseModal()} />
          {currentStep === 2 && <StyledArrow onClick={handlePreviousStep} />}
        </CheckoutHeader>
        <ContentContainer>
          <CheckoutHeading>{activeItemName} for {activeSite}</CheckoutHeading>
          {currentStep === 1 && <PricingTable />}
          {currentStep === 2 && <CheckoutForm /> }
        </ContentContainer>
      </CheckoutContainer>
    </Modal>
  );
};
