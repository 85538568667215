import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { useSelector } from 'services/hooks';
import { selectActiveSiteData } from 'selectors/sites';
import RegistrationHeaderImage from 'images/SaintsandStars-pricing.jpg';
import { SiteSelector } from 'common/interaction';
import { HalfHeader } from 'common/layout';

export const PricingHeader = () => {
  const { t } = useTranslation();
  const activeSite = useSelector(selectActiveSiteData);

  return (
    <HalfHeader
      subtitle={t('our pricing of')}
      value={activeSite}
      imageUrl={RegistrationHeaderImage}
    >
      <SiteSelector label={t('prices of')} />
    </HalfHeader>
  );
};
