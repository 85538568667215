import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { configureImageSrc, getSlug } from 'services';

import { TrainerGrid, Trainer, TrainerImage, ExtendedText } from './styled';

export const TrainerOverview = () => {
  const { language } = useI18next();
  const data = useStaticQuery<GatsbyTypes.TrainersQuery>(query);
  const trainers = data.allContentfulTypeTrainer.edges.filter((trainer) => trainer.node.node_locale === language) as TrainerType;

  return (
    <TrainerGrid>
      {trainers.map(({ node }) => (
        <Trainer to={getSlug('trainer', node.slug) || ''} key={node.id}>
          <TrainerImage src={configureImageSrc(node.frontImage.file.url, 700)} />
          <ExtendedText $weight={700} variant="black" $size={20} $margin="24px 32px 0" $lineHeight="24">
            {node.name}
          </ExtendedText>
          <ExtendedText variant="gray" $size={16} $margin="12px 32px 0" $lineHeight="24">
            {node.tagline}
          </ExtendedText>
        </Trainer>
      ))}
    </TrainerGrid>
  );
};

type TrainerType = {
  node: {
    slug: string;
    id: string;
    frontImage: {
      file: {
        url: string;
      };
    };
    name: string;
    tagline: string;
  }
}[];

const query = graphql`
  query Trainers {
    allContentfulTypeTrainer {
      edges {
        node {
          id
          name
          node_locale
          tagline
          slug
          frontImage {
            id
            file {
              url
            }
          }
        }
      }
    }
  }
`;
