import styled from 'styled-components';

import { media } from 'styles/utils';

export const ColumnContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;

  ${media.desktop`
    grid-template-columns: 1fr 1fr;
    gap: 56px 24px;
  `}
`;
