import styled from 'styled-components';

import { Heading, Text } from 'common/typography';
import { media } from 'styles/utils';

export const MembershipTermContainer = styled.div`
  grid-gap: 24px;
  margin-bottom: 24px;
  
  ${media.tablet`
    display: flex;
  `}
`;

export const MembershipTermOptionTitle = styled(Heading)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
`;

export const MembershipTermOptionText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray.dark};
`;
