import * as i from 'types';
import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const MembershipCardContainer = styled.button<MembershipCardContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray.tableBackground};
  padding: 24px 8px;
  border-radius: 4px;
  position: relative;
  border: none;
  flex: 1;
  border: 1px solid transparent;

  &:last-child {
    margin-bottom: 0px;
  }

  ${({ theme, isSelected }) => isSelected && css`
    background-color: ${theme.colors.gray.lightest};
    border: 1px solid ${theme.colors.primary};
  `};

  ${media.tablet`
    background-color: ${({ theme }) => theme.colors.white};
    margin-bottom: 0;
    padding: 24px;
  `}
`;

type MembershipCardContainerProps = {
  isSelected: boolean;
  onClick?: i.OnClick<HTMLButtonElement>;
};

export const MembershipHeaderCheck = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray.line};

  ${media.tablet`
    top: 16px;
    left: 16px;
  `}
`;

export const Check = styled.div<CheckProps>`
  height: 8px;
  width: 8px;
  border-radius: 100%;

  ${({ theme, isSelected }) => isSelected && css`
    background-color: ${theme.colors.primary};
  `};
`;

type CheckProps ={
  isSelected: boolean;
};
