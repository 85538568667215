import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProgressbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  gap: 8px;
`;

export const Progressbar = styled.div<ProgressBarProps>`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white.opacity};
  transition: .2s all;

  ${({ active }) => active && css`
    background-color: ${({ theme }) => theme.colors.white};
  `}
`;

type ProgressBarProps = {
  active?: boolean;
};
