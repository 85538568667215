import * as i from 'types';
import React from 'react';
import { graphql } from 'gatsby';

import { configureImageSrc } from 'services';

import { ContentContainer, ExtendedImage } from './styled';

export const ImageBlock: React.FC<ImageBlockProps> = ({ content }) => {
  const imageUrl = content?.image?.file.url;

  return (
    <ContentContainer>
      <ExtendedImage src={configureImageSrc(imageUrl)} alt={content?.image?.description} />
    </ContentContainer>
  );
};

export const query = graphql`
  fragment ImageBlockFragment on ContentfulComponentImageBlock {
    image {
      description
      file {
        url
      }
    }
  }
`;

type ImageBlockProps = {
  content: i.ComponentImageBlock;
};
