import styled, { css } from 'styled-components';

import { Text } from 'common/typography';

export const MobileBenefitItemContainer = styled.div<MobileBenefitItemProps>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 36px 0 16px;

  svg {
    height: 16px;
    width: 16px;
  }

  ${({ theme, isSelected }) => isSelected && css`
    background-color: ${theme.colors.gray.tableBackground};
  `};
`;

type MobileBenefitItemProps = {
  isSelected: boolean;
};

export const MobileBenefitText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray.dark};
`;
