import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { useAuthenticationUser, useDispatch, useSelector } from 'services/hooks';
import ArrowIcon from 'vectors/arrowLeft.svg';
import { selectActiveContract } from 'selectors';
import { getMeContracts } from 'ducks/meSeries';
import { ContentContainer } from 'common/layout';
import { Heading, Text } from 'common/typography';
import { configureImageSrc } from 'services';

import {
  HolyFriend, HolyFriendImage, HolyFriendsHeader, HolyFriendsContainer, HolyFriendsContent,
  HolyLink,
} from './styled';

export const HolyFriends: React.FC<HolyFriendsProps> = ({ content }) => {
  const dispatch = useDispatch();
  const activeContract = useSelector(selectActiveContract);
  const { t } = useTranslation();
  const { determined, authenticated } = useAuthenticationUser();

  React.useEffect(() => {
    if (authenticated) dispatch(getMeContracts());
  }, [determined, authenticated]);

  return (
    <ContentContainer>
      <HolyFriendsHeader>
        <Heading variant="black" as="h2" upperCase weight={700} margin="0 0 8px" isCentered>
          {content.title}
        </Heading>
        <Text variant="gray" $size={20} $lineHeight="24px">{content.subtitle}</Text>
      </HolyFriendsHeader>
      <HolyFriendsContainer>
        {content.holyFriends.map((friend) => (
          <HolyFriend key={friend.id}>
            <HolyFriendImage imageUrl={configureImageSrc(friend.image.file.url)} />
            <HolyFriendsContent>
              <Heading variant="black" as="h3" margin="0">{friend.companyName}</Heading>
              <Text variant="gray" $margin="8px 0 16px">{friend.description.description}</Text>
              {activeContract ? (
                <>
                  {friend.discountLink ? (
                    <HolyLink as="a" href={friend.discountLink} target="_blank" rel="noreferrer">
                      <Text $weight={700} $size={16}>
                        {friend.discountCode} <ArrowIcon />
                      </Text>
                    </HolyLink>
                  ) : (
                    <Text $weight={700} $size={16}>{friend.discountCode}</Text>
                  )}
                </>
              ) : (
                <HolyLink to="/pricing">
                  {t('Unlock your discount')} <ArrowIcon />
                </HolyLink>
              )}
            </HolyFriendsContent>
          </HolyFriend>
        ))}
      </HolyFriendsContainer>
    </ContentContainer>
  );
};

export const query = graphql`
  fragment HolyFriendsFragment on ContentfulComponentHolyFriends {
    id
    title
    subtitle
    holyFriends {
      id
      companyName
      description {
        description
      }
      discountCode
      discountLink
      image {
        file {
          url
        }
      }
    }
  }
`;

type HolyFriendsProps = {
  content: i.ComponentHolyFriends;
};

