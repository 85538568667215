import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const MembershipSummaryContainer = styled.div`
  grid-gap: 24px;

  ${media.tablet`
    display: flex;
  `}
`;

export const MembershipSummaryDetails = styled.div`
  ${media.tablet`
    display: flex;
    flex: 2;
    align-items: center;
    padding: 0 40px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
  `}
`;

export const MembershipSummaryDetailsOverview = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.colors.gray.dark};
`;

export const MembershipSummaryPriceRow = styled.div<MembershipSummaryPriceRowProps>`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;

  ${({ theme, hasBorderTop }) => hasBorderTop && css`
    border-top: 1px solid ${theme.colors.gray.dark};
    color: ${({ theme }) => theme.colors.black};
  `};
`;

type MembershipSummaryPriceRowProps = {
  hasBorderTop?: boolean;
};

export const MembershipSummaryButtonWrapper = styled.div`
  margin-top: 32px;

  ${media.tablet`
    flex: 1;
    justify-content: center;
    padding-left: 80px;
    margin-top: unset;
  `}
`;
