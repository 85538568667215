import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Text } from 'common/typography';
import PersonalTrainerBanner from 'images/personalTraining.png';

import {
  FirstTimerContainer, ContainerBackgroundImage, ContentContainer, TitleContainer,
  ButtonContainer, ExtendedButton, HeaderContainer, ExtendedHeading,
} from './styled';

export const PersonalTrainer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FirstTimerContainer>
      <ContainerBackgroundImage src={PersonalTrainerBanner} alt="first timer" />
      <ContentContainer>
        <HeaderContainer />
        <TitleContainer>
          <ExtendedHeading margin="0" upperCase>{t('personal trainer')}</ExtendedHeading>
          <Text variant="white" $lineHeight="22">{t('personal trainer goal')}</Text>
        </TitleContainer>
        <ButtonContainer>
          <ExtendedButton
            size="fullWidth"
            to="/concepts/personal-training-amsterdam"
          >
            {t('read more')}
          </ExtendedButton>
        </ButtonContainer>
      </ContentContainer>
    </FirstTimerContainer>
  );
};
