import * as i from 'types';
import * as React from 'react';

import { useCheckoutContext } from 'modules/PricingPage';
import CheckIcon from 'vectors/largeCheck.svg';
import CloseIcon from 'vectors/largeClose.svg';

import { MobileBenefitItemContainer, MobileBenefitText } from './styled';

export const MobileBenefitItem = ({ benefit, membership }: MobileBenefitItemProps) => {
  const selectedBenefit = membership.benefits
    ?.find(({ shortDescription }) => shortDescription === benefit.shortDescription);
  const { selectedMembership } = useCheckoutContext();

  const CheckType = selectedBenefit?.checkbox
    ? <CheckIcon />
    : <CloseIcon />;

  if (!selectedBenefit) {
    return (
      <MobileBenefitItemContainer isSelected={selectedMembership?.id === membership.id} />
    );
  }
  return (
    <MobileBenefitItemContainer isSelected={selectedMembership?.id === membership.id}>
      {
        selectedBenefit.isText
          ? <MobileBenefitText>{selectedBenefit.text}</MobileBenefitText>
          : CheckType
      }
    </MobileBenefitItemContainer>
  );
};

type MobileBenefitItemProps = {
  benefit: i.Benefit;
  membership: i.ComponentMembershipBlock;
};
