import styled from 'styled-components';
import { media } from 'styles/utils';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px 60px;
  align-items: center;

  ${media.desktop`
    max-width: 1152px;
    margin: 0 auto;
  `}
`;

export const Video = styled.video`
  width: 100%;
  max-height: 552px;

  ${media.desktop`
    max-width: 1152px;
    margin: 0 auto;
  `}
`;

