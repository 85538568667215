import styled from 'styled-components';

import { media } from 'styles/utils';

export const PricingCardContainer = styled.div<CardContainerProps>`
  background-color: ${({ theme }) => theme.colors.gray.light};
  display: flex;
  padding: ${({ hasLabel }) => hasLabel ? '32px 16px 16px' : '16px'};
  flex-direction: column;
  justify-content: center;
  gap: 18px;
  border-radius: 4px;
  margin-bottom: 16px;
  max-width: 500px;
  position: relative;
  
  ${media.desktop`
    width: 100%;
    gap: 25px;
    margin: 0;
  `}
`;

type CardContainerProps = {
  hasLabel?: boolean;
};

export const LabelContainer = styled.div`
  padding: 4px;
  background-color: ${({ theme }) => theme.colors.orange};
  border-radius: 0px 0px 4px 4px;
  position: absolute;
  top: 0;
  right: 24px;

  ${media.desktop`
    padding: 8px;
  `}
`;
