import styled from 'styled-components';
import { Link } from 'gatsby';

import { media } from 'styles/utils';

export const HolyFriend = styled.div`
  margin-bottom: 48px;

  ${media.desktop`
    display: flex;
    align-items: center;
  `}
`;

export const HolyFriendImage = styled.div<HolyFriendProps>`
  background: ${({ imageUrl }) => `url(${imageUrl})`};
  width: 160px;
  height: 106px;
  background-size: cover;
  background-position: center;
  margin: 0 0 16px;

  ${media.desktop`
    margin: 0 32px 0 0;
  `}
`;

type HolyFriendProps = {
  imageUrl: string;
};

export const HolyFriendsHeader = styled.div`
  margin-bottom: 48px;
  text-align: center;
`;

export const HolyFriendsContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  a {
    text-decoration: none;
  }
`;

export const HolyFriendsContent = styled.div`
  flex: 1;
`;

export const HolyLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black};

  svg {
    transform: rotate(180deg);
    width: 10px;
    margin-left: 10px;
    margin-bottom: -2px;
  }
`;
