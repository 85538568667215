import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { Button } from 'common/interaction';
import { getSlug } from 'services';

import { ContentContainer } from './styled';

export const CtaButton: React.FC<CtaButtonProps> = ({ content, noPadding }) => {
  return (
    <ContentContainer
      $align={content?.alignment}
      noPadding={noPadding}
    >
      {content.internalCtaTarget ? (
        <Button
          variant="CTA"
          to={getSlug(content.internalCtaTarget.sys.contentType.sys.id, content.internalCtaTarget.slug)}
        >
          {content.ctaText}
        </Button>
      ) : (
        <Button
          variant="CTA"
          href={content.externalCtaTarget}
          urlTarget={content.shouldOpenInNewTab ? 'blank' : 'self'}
        >
          {content.ctaText}
        </Button>
      )}
    </ContentContainer>
  );
};

export const query = graphql`
  fragment CtaButtonFragment on ContentfulComponentCtaButton {
    id
    title
    alignment
    ctaText
    internalCtaTarget {
      ... on ContentfulPageGeneral {
        slug
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on ContentfulTypeConcept {
        slug
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on ContentfulTypeStudio {
        slug
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on ContentfulTypeTrainer {
        slug
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on ContentfulWebsiteAlternativePages {
        slug
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
    externalCtaTarget
    shouldOpenInNewTab
  }
`;

type CtaButtonProps = {
  content: i.ComponentCtaButton;
  noPadding?: boolean;
};
