import styled from 'styled-components';

import { Button } from 'common/interaction';
import { Heading } from 'common/typography';

import { media } from 'styles/utils';

export const FirstTimerContainer = styled.div`
  position: relative;
  border-radius: 4px;
  overflow: hidden;
`;

export const ContainerBackgroundImage = styled.img`
  object-fit: cover;
  height: 200px;
  min-height: 25vh;
  width: 100%;
  border-radius: 4px;

  ${media.desktop`
    height: 263px;
  `}
`;

export const TitleContainer = styled.div`
  padding: 10px 16px;

  ${media.desktop`
    padding: 24px;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const ExtendedHeading = styled(Heading)`
  font-size: 18px;
`;

export const ButtonContainer = styled.div`
  padding: 0 16px 24px;

  ${media.desktop`
    width: 50%;
    padding: 0 16px 30px 24px;
  `}
`;

export const ExtendedButton = styled(Button)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};
`;

export const HeaderContainer = styled.div`
  align-self: flex-end;
  flex: 1;
`;
