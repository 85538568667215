import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { ContentContainer } from 'common/layout';
import { Heading } from 'common/typography';

export const SpotifyEmbed: React.FC<SpotifyEmbedProps> = ({ content }) => {
  return (
    <ContentContainer>
      <Heading as="h2" variant="black" isCentered>{content.title}</Heading>
      <div dangerouslySetInnerHTML={{ __html: content.spotifyEmbed?.spotifyEmbed }} />
    </ContentContainer>
  );
};

type SpotifyEmbedProps = {
  content: i.ComponentSpotifyEmbed;
};

export const query = graphql`
  fragment SpotifyEmbedFragment on ContentfulComponentSpotifyEmbed {
    id
    title
    spotifyEmbed {
      spotifyEmbed
    }
  }
`;
