import styled from 'styled-components';

import { media } from 'styles/utils';
import { Heading, Text } from 'common/typography';
import { Button } from 'common/interaction';

export const ValidToText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray.medium};
  font-size: 12px;
  text-align: center;
  margin: 0 auto;

  ${media.desktop`
    max-width: 75%;
  `}
`;

export const PerMembershipText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: 14px;
  line-height: 20px;
`;

export const CommitmentText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: 14px;
  line-height: 20px;
  align-self: center;
`;

export const PriceText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray.dark};
  line-height: 20px;

  ${media.desktop`
    font-size: 14px;
    margin-bottom: 15px;
    color: ${({ theme }) => theme.colors.gray.medium};
  `}
`;

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: ${({ margin }) => margin || '0'};

  ${media.desktop<RowProps>`
    flex-direction: column;
    gap: ${({ gap }) => gap || '0'};
  `}
`;

type RowProps = {
  margin?: string;
  gap?: string;
};

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || '0'};
  justify-content: center;
  margin: 0 auto;
`;

type ColumnProps = {
  gap?: string;
};

export const ExtendedHeading = styled(Heading)`
  font-weight: bold;
  letter-spacing: 0;
  font-size: 16px;
  text-align: center;
`;

export const PriceHeading = styled(Heading)`
  font-size: 40px;
  letter-spacing: 0;
  text-align: center;
  line-height: 44px;

  ${media.desktop`
    font-size: 30px;
    line-height: 40px;
  `}
`;

export const ExtendedButton = styled(Button)`
  height: 48px;
  padding: 0;
  width: 100%;
  align-self: center;

  ${media.desktop`
    max-width: 355px;
  `}
`;

export const BenefitContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  ${media.desktop`
    padding: 0 48px;
  `}
`;

export const BenefitIconContainer = styled.div`

  ${media.desktop`
    margin-right: 12px;
    padding-top: 3px;
  `}
`;

export const Benefits = styled.div`
  padding: 0 20px;
  margin-bottom: 18px;
`;
