import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { formatPrice } from 'services';
import { useCheckoutContext } from 'modules/PricingPage';

import { MembershipStep, MembershipCard } from '../';
import {
  MembershipTermContainer,
  MembershipTermOptionTitle,
  MembershipTermOptionText,
} from './styled';

export const MembershipTerm = () => {
  const { t } = useTranslation();
  const { selectedMembershipType, setSelectedMembershipType } = useCheckoutContext();

  // Hardcoded price for the additional fee of a flex membership
  const flexAdditionalPrice = 30;

  return (
    <MembershipTermContainer>
      <MembershipStep
        step={t('STEP 2/3')}
        title={t('membership term')}
        description={t('Choose a membership that fits the best for you. Train for a better price or go for maximum freedom with a flex membership')}
      />
      <MembershipCard
        onClick={() => setSelectedMembershipType('annually')}
        isSelected={selectedMembershipType === 'annually'}
      >
        <MembershipTermOptionTitle as="h2">{t('1 year')}</MembershipTermOptionTitle>
        <MembershipTermOptionText>
          {t('Freeze your membership for up to 28 days. 12 month commitment period. After 11 months, you can cancel anytime with a 4-week cancellation period.')}
        </MembershipTermOptionText>
      </MembershipCard>
      <MembershipCard
        onClick={() => setSelectedMembershipType('flex')}
        isSelected={selectedMembershipType === 'flex'}
      >
        <MembershipTermOptionTitle as="h2">{t('Flex')} {`(+${formatPrice(flexAdditionalPrice, { hideZeroDecimals: true })})`}</MembershipTermOptionTitle>
        <MembershipTermOptionText>
          {t('Unlimited freeze options.Your membership will automatically renew. Cancel anytime with a 4-week cancellation period.')}
        </MembershipTermOptionText>
      </MembershipCard>
    </MembershipTermContainer>
  );
};
