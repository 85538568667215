import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const PageHeaderContainer = styled.div<PageHeaderContainerProps>`
  height: 100vh;
  width: 100%;
  background: ${({ imageUrl }) => `url(${imageUrl})`};
  background-position: center;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 60px;

  ${media.desktop`
    margin-bottom: 120px;
  `}
`;

type PageHeaderContainerProps = {
  imageUrl?: string;
};

export const PageHeaderContent = styled.div<PageHeaderContentProps>`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  z-index: 1;
  padding-left: 156px;

  ${({ $isCentered }) => $isCentered && css`
    padding-left: 0;
    flex-direction: column;
    align-items: center;
    text-align: center;
  `}
`;

type PageHeaderContentProps = {
  $isCentered: boolean;
};

export const LogoWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 36px;

  ${media.desktop`
    top: 30px;
  `}
`;

export const VideoContainer = styled.video`
  position: absolute;
  left: 50%;
  top: 0;
  right: 0;
  bottom: 0;
  max-height: 100vh;
  min-width: 100vmin;
  transform: translateX(-50%);
  overflow: hidden;
  box-sizing: content-box;
  
  ${media.tablet`
    min-height: 100vh;
    min-width: 100vw;
    max-height: none;
  `};
`;
