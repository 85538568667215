import * as React from 'react';

import { useCheckoutContext } from 'modules/PricingPage';
import CheckIcon from 'vectors/largeCheck.svg';
import CloseIcon from 'vectors/largeClose.svg';

import { MembershipCard, MembershipHeader } from '../';
import {
  BenefitsContainer, BenefitItem, BenefitText,
} from './styled';

export const MembershipDetails = ({ isCollapsable, showAllBenefits, isUpgrade }: MembershipDetailsProps) => {
  const {
    selectedMembership, setSelectedMembership, setSelectedMembershipType, localizedMemberships, benefits,
    benefitsShortList,
  } = useCheckoutContext();
  const benefitsFormatted = isCollapsable
    ? showAllBenefits ? benefits : benefitsShortList
    : benefits;

  React.useEffect(() => {
    if (isUpgrade) {
      setSelectedMembershipType('upgrade');
    }
  }, [isUpgrade]);

  return (
    <>
      {localizedMemberships.map((membership) => {
        const isSelected = selectedMembership?.id === membership.id;

        return (
          <MembershipCard
            key={membership.id}
            isSelected={isSelected}
            onClick={!isUpgrade || isSelected ? () => setSelectedMembership(membership) : undefined}
          >
            <MembershipHeader membership={membership} />
            <BenefitsContainer>
              {benefitsFormatted.map((benefit) => {
                const selectedBenefit = membership.benefits?.find(({ shortDescription }) => shortDescription === benefit.shortDescription);

                if (!selectedBenefit) return <BenefitItem key={benefit.id} />;
                return (
                  <BenefitItem key={benefit.id}>
                    {selectedBenefit.isText && <BenefitText>{selectedBenefit.text}</BenefitText>}
                    {!selectedBenefit.isText && selectedBenefit.checkbox === true && <CheckIcon />}
                    {!selectedBenefit.isText && selectedBenefit.checkbox === false && <CloseIcon />}
                  </BenefitItem>
                );
              })}
            </BenefitsContainer>
          </MembershipCard>
        );
      })}
    </>
  );
};

type MembershipDetailsProps = {
  isCollapsable?: boolean;
  showAllBenefits?: boolean;
  isUpgrade?: boolean;
};
