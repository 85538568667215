import styled from 'styled-components';

import { Text } from 'common/typography';
import { media } from 'styles/utils';

export const CountdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 24px;
  margin-bottom: 60px;

  ${media.desktop`
    margin-top: 40px;
  `}
`;

export const Counter = styled(Text)`
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 4px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  ${media.desktop`
    font-size: 120px;
    line-height: 120px;
  `}
`;

export const CounterLabel = styled(Text)`
  font-size: 12px;

  ${media.desktop`
    font-size: 14px;
  `}
`;
