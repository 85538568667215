import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { CheckoutModal, PricingOverview, CheckoutProvider } from 'modules/PricingPage';
import { ContentContainer } from 'common/layout';

export const PricingAdvancedBlock: React.FC<PricingAdvancedBlockProps> = ({ content }) => {
  const membershipIds = content.memberships.map((membership) => membership.id);

  return (
    <ContentContainer hasNoPadding>
      <CheckoutProvider filteredMemberships={membershipIds} defaultMembershipIdFromBlock={content.defaultSelectedMembership?.id}>
        <PricingOverview isCollapsable={!content.showAllBenefits} isUpgrade={content.isUpgradeBlock} />
        <CheckoutModal />
      </CheckoutProvider>
    </ContentContainer>
  );
};

export const query = graphql`
  fragment PricingAdvancedBlockFragment on ContentfulComponentPricingAdvancedBlock {
    id
    title
    memberships {
      id
    }
    showAllBenefits
    defaultSelectedMembership {
      id
    }
    isUpgradeBlock
  }
`;

type PricingAdvancedBlockProps = {
  content: i.ComponentPricingAdvancedBlock;
};
