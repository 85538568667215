import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { Heading, Text } from 'common/typography';
import { CtaButton } from 'modules/contentBlocks';
import { configureImageSrc } from 'services';

import { BannerContainer, BannerContent } from './styled';

export const Banner: React.FC<BannerProps> = ({ content }) => {
  const imageUrl = content.backgroundMedia?.file?.url;

  return (
    <BannerContainer imageUrl={configureImageSrc(imageUrl, 1400)}>
      <BannerContent>
        <Heading upperCase>{content.title}</Heading>
        <Text
          variant="white"
          $uppercase
          $size={16}
          $margin="0 0 24px"
          $lineHeight="24"
        >
          {content.subtitle}
        </Text>
        <CtaButton
          content={{ ...content.ctaButton }}
          noPadding
        />
      </BannerContent>
    </BannerContainer>
  );
};

export const query = graphql`
  fragment BannerFragment on ContentfulComponentBanner {
    id
    backgroundMedia {
      file {
        contentType
        url
      }
    }
    title
    subtitle
    ctaButton {
      ...on Node {
        ...CtaButtonFragment
      }
    }
  }
`;

type BannerProps = {
  content: i.ComponentBanner;
};
