import React from 'react';

import { ProgressbarContainer, Progressbar, Container } from './styled';

export const StepButtonGroup: React.FC<StepButtonGroupProps> = ({ currentStep  }) => {
  return (
    <Container>
      <ProgressbarContainer>
        <Progressbar active={currentStep === 1} />
        <Progressbar active={currentStep === 2} />
      </ProgressbarContainer>
    </Container>
  );
};

type StepButtonGroupProps = {
  currentStep: number;
};
