import * as i from 'types';
import * as React from 'react';

import { MembershipCard, MembershipsBlockHeader, PricingCardWrapper } from 'modules/PricingPage';

import { Layout } from './styled';

export const MembershipsBlock = ({ isContentBlock, memberships }: MembershipsBlockProps) => {
  if (memberships?.length === 0) return null;

  const isCentered = memberships.length === 1;

  return (
    <Layout {...{ isContentBlock }}>
      {!isContentBlock && (
        <MembershipsBlockHeader />
      )}
      <PricingCardWrapper {...{ isCentered, isContentBlock }}>
        {memberships.map((el) => (
          <MembershipCard key={el.id} item={el} />
        ))}
      </PricingCardWrapper>
    </Layout>
  );
};

type MembershipsBlockProps = {
  isContentBlock?: boolean;
  memberships: i.Serie[];
};
