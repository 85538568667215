import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { Heading, TextContent } from 'common/typography';
import { Accordion } from 'common/interaction';
import { CtaButton } from 'modules/contentBlocks';
import { configureImageSrc } from 'services';

import { UspContainer, UspContent, UspImage, UspContentContainer } from './styled';

export const UspBlock: React.FC<UspBlockProps> = ({ content }) => {
  const accordionItems = [
    ...(content.uspTitle1 ? [{
      title: content.uspTitle1,
      text: content.uspText1?.uspText1,
    }] : []),
    ...(content.uspTitle2 ? [{
      title: content.uspTitle2,
      text: content.uspText2?.uspText2,
    }] : []),
    ...(content.uspTitle3 ? [{
      title: content.uspTitle3,
      text: content.uspText3?.uspText3,
    }] : []),
    ...(content.uspTitle4 ? [{
      title: content.uspTitle4,
      text: content.uspText4?.uspText4,
    }] : []),
  ];

  return (
    <UspContainer alignRight={content.alignRight}>
      <UspImage src={configureImageSrc(content.image?.file?.url, 800)} alignRight={content.alignRight} />
      <UspContentContainer alignRight={content.alignRight}>
        <UspContent alignRight={content.alignRight}>
          <Heading as="h3" variant="black" upperCase weight={700}>{content.title}</Heading>
          <TextContent content={content.content.raw} alignment="left" />
          <Accordion items={accordionItems} />
          {content.ctaButton && (
            <CtaButton
              content={{ ...content.ctaButton }}
              noPadding
            />
          )}
        </UspContent>
      </UspContentContainer>
    </UspContainer>
  );
};

type UspBlockProps = {
  content: i.ComponentUspBlock;
};

export const query = graphql`
  fragment UspBlockFragment on ContentfulComponentUspBlock {
    id
    title
    alignRight
    image {
      file {
        contentType
        url
      }
    }
    content {
      raw
    }
    ctaButton {
      ...on Node {
        ...CtaButtonFragment
      }
    }
    uspTitle1
    uspText1 {
      uspText1
    }
    uspTitle2
    uspText2 {
      uspText2
    }
    uspTitle3
    uspText3 {
      uspText3
    }
    uspTitle4
    uspText4 {
      uspText4
    }
  }
`;
