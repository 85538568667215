import styled from 'styled-components';

import { media } from 'styles/utils';
import { Text } from 'common/typography';
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next';

export const HighlightContainer = styled.div`
  margin: 0 0 60px;
  padding: 0 24px;

  ${media.desktop`
    display: flex;
    margin: 0 100px 120px;
    padding: 0;
  `}
`;

export const HighlightBlock = styled(GatsbyLink)<HighlightBlockProps>`
  height: 300px;
  background: ${({ imageUrl }) => `url(${imageUrl})`};
  background-position: center;
  background-size: cover;
  transition: width .3s ease;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  ${media.desktop`
    width: 50%;
    height: 50vw;
    max-height: 600px;

    &:hover {
      width: 70%;

      span, p {
        opacity: 1;
      }
    }
  `}

  h3, p {
    position: relative;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: rgba(0,0,0,0.2);
  }
`;

type HighlightBlockProps = {
  imageUrl: string;
};

export const HighlightDescription = styled(Text)`
  text-align: center;
  transition: opacity .3s ease .3s;
  display: none;
  color: ${({ theme }) => theme.colors.white};

  ${media.desktop`
    opacity: 0;
    display: block;
  `}
`;

export const ReadMore = styled.span`
  position: absolute;
  text-decoration: none;
  right: 32px;
  bottom: 32px;
  color: ${({ theme }) => theme.colors.gray.light};
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  transition: opacity .3s ease .3s;

  ${media.desktop`
    opacity: 0;
  `}

  &::before {
    content: '';
    width: 16px;
    height: 1px;
    left: -24px;
    top: 6px;
    display: block;
    position: absolute;
    background: ${({ theme }) => theme.colors.gray.light};
  }
`;
