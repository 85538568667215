import * as React from 'react';

import { Text } from 'common/typography';

import {
  MembershipStepContainer,
  MembershipStepTitle,
  MobileStep,
  MembershipStepHeader,
  Step,
} from './styled';

export const MembershipStep: React.FC<MembershipStepContainerProps> = ({
  children, step, title, description,
}) => {
  return (
    <MembershipStepContainer>
      <MembershipStepHeader>
        {step && <Step>{step}</Step>}
        <MembershipStepTitle as="h2">{title}</MembershipStepTitle>
        <MobileStep>{step}</MobileStep>
        <Text>{description}</Text>
      </MembershipStepHeader>
      {children}
    </MembershipStepContainer>
  );
};

type MembershipStepContainerProps = {
  step?: string;
  title: string;
  description: string;
};
