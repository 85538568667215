import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Tooltip } from 'react-tippy';

import { useCheckoutContext } from 'modules/PricingPage';
import { useDevice } from 'services/hooks';

import { Button } from 'common/interaction';
import {
  MembershipDetails, MembershipTerm, MembershipStep, MembershipSummary, MobileMembershipDetails,
} from './components';
import {
  PricingOverviewContainer,
  MembershipsColumns,
  DesktopBenefits,
  BenefitItem,
  TooltipContainer,
  TooltipContent,
  ButtonContainer,
} from './styled';

export const PricingOverview = ({ isCollapsable, isUpgrade }: PricingOverviewProps) => {
  const { t } = useTranslation();
  const { benefits } = useCheckoutContext();
  const { isMobile } = useDevice();
  const [showAllBenefits, setShowAllBenefits] = React.useState(isCollapsable ? false : true);
  const benefitsFormatted = isCollapsable
    ? showAllBenefits ? benefits : benefits?.slice(0, 5)
    : benefits;

  return (
    <PricingOverviewContainer>
      <MembershipsColumns>
        <MembershipStep
          step={isUpgrade ? '' : t('STEP 1/3')}
          title={t('memberships')}
          description={t('unlimited gold for those who truly have no limits')}
        >
          <DesktopBenefits>
            {benefitsFormatted.map((benefit) => (
              <BenefitItem key={benefit.id}>
                {benefit.shortDescription}
                {benefit.longDescription !== benefit.shortDescription && (
                  <Tooltip html={<TooltipContent>{benefit.longDescription}</TooltipContent>}>
                    <TooltipContainer>i</TooltipContainer>
                  </Tooltip>
                )}
              </BenefitItem>
            ))}
          </DesktopBenefits>
        </MembershipStep>
        {isMobile ? (
          <MobileMembershipDetails
            isCollapsable={isCollapsable}
            showAllBenefits={showAllBenefits}
            isUpgrade={isUpgrade}
          />
        ) : (
          <MembershipDetails
            isCollapsable={isCollapsable}
            showAllBenefits={showAllBenefits}
            isUpgrade={isUpgrade}
          />
        )}
      </MembershipsColumns>
      {isCollapsable && !showAllBenefits && (
        <ButtonContainer>
          <Button variant="secondary" onClick={() => setShowAllBenefits(!showAllBenefits)}>{t('show more')} benefits</Button>
        </ButtonContainer>
      )}
      {!isUpgrade && <MembershipTerm />}
      <MembershipSummary isUpgrade={isUpgrade} />
    </PricingOverviewContainer>
  );
};

type PricingOverviewProps = {
  isCollapsable?: boolean;
  isUpgrade?: boolean;
};
