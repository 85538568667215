import * as i from 'types';
import * as React from 'react';

import { MembershipCardContainer, MembershipHeaderCheck, Check } from './styled';

export const MembershipCard: React.FC<MembershipCardProps> = ({
  children, isSelected, onClick,
}) => {
  return (
    <MembershipCardContainer {...{ onClick, isSelected }}>
      {onClick && <MembershipHeaderCheck><Check isSelected={isSelected} /></MembershipHeaderCheck>}
      {children}
    </MembershipCardContainer>
  );
};

type MembershipCardProps = {
  isSelected: boolean;
  onClick: i.OnClick<HTMLButtonElement> | undefined;
};
