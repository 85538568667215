import styled, { css } from 'styled-components';

export const CarouselContainer = styled.div`
  .carousel {
    width: 100%;
  }

  .carousel__slide {
    height: 400px;
  }

  .swiper-button-disabled {
    display: block;
    opacity: .3;
    cursor: default;
  }
`;

export const CarouselSlide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CarouselImage = styled.div<CarouselImageProps>`
  height: 130px;
  width: 130px;
  border-radius: 100%;
  background: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-position: center;
  margin-bottom: 24px;
  transition: all .3s ease;

  ${({ isActive }) => isActive && css`
    height: 170px;
    width: 170px;
  `}
`;

type CarouselImageProps = {
  isActive: boolean;
  imageUrl: string;
};

export const CarouselContent = styled.div`
  text-align: center;
`;

