import * as i from 'types';
import * as React from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { useCheckoutContext } from 'modules/PricingPage';
import { Text, Tag } from 'common/typography';
import { useAuthenticationUser } from 'services/hooks';

import {
  FirstTimerContainer, ContainerBackgroundImage, ContentContainer, TitleContainer, ButtonContainer,
  ExtendedButton, HeaderContainer, ExtendedHeading, PromoWrapper,
} from './styled';

export const PromotionClass = ({
  title, subTitle, tagLabel, backgroundImg, serie,
}: PromotionClassProps) => {
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const { handleOpenModal } = useCheckoutContext();
  const { authenticated } = useAuthenticationUser();

  const handleModal = (item: i.Serie) => {
    if (!authenticated) {
      return navigate('/register',
        {
          state: {
            redirectUrl: '/pricing',
          },
        });
    }

    handleOpenModal(item);
  };

  return (
    <PromoWrapper>
      <FirstTimerContainer>
        <ContainerBackgroundImage src={backgroundImg} alt="first timer" />
        <ContentContainer>
          <HeaderContainer>
            {tagLabel && (
              <Tag item={tagLabel} />
            )}
          </HeaderContainer>
          <TitleContainer>
            <ExtendedHeading margin="0" upperCase>{title}</ExtendedHeading>
            <Text variant="white" $lineHeight="22">{subTitle}</Text>
          </TitleContainer>
          <ButtonContainer>
            <ExtendedButton
              size="fullWidth"
              onClick={() => handleModal(serie)}
            >
              {t('buy now')}
            </ExtendedButton>
          </ButtonContainer>
        </ContentContainer>
      </FirstTimerContainer>
    </PromoWrapper>
  );
};

type PromotionClassProps = {
  title: string;
  subTitle: string;
  serie: i.Serie;
  tagLabel?: string;
  backgroundImg?: string;
};
