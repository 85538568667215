import styled from 'styled-components';

import { Text } from 'common/typography';

export const BenefitsContainer = styled.div`
  width: 100%;
  margin-top: auto;
`;

export const BenefitItem = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.line};
  height: 52px;

  &:last-child {
    border-bottom: none;
  }

  svg {
    height: 24px;
    width: 24px;
  }
`;

export const BenefitText = styled(Text)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray.dark};
`;
