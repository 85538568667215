import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { Button } from 'common/interaction';
import { Heading, Headline1 } from 'common/typography';
import { VideoModal } from 'common/layout';
import { Logo, Headline1Variant } from 'common/general';

import { PageHeaderContainer, PageHeaderContent, LogoWrapper } from './styled';

export const PageHeaderVideoModal: React.FC<ContentProps> = ({ content }) => {
  const [isVideoOpen, setIsVideoOpen] = React.useState(false);
  const backgroundMedia = content?.backgroundMedia?.file?.url;
  const videoUrl = content?.videoModalContent?.file?.url;

  return (
    <PageHeaderContainer imageUrl={backgroundMedia}>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <PageHeaderContent>
        <Headline1
          isCentered
          margin="0 0 16px"
          {...Headline1Variant}
        >
          {content?.title}
        </Headline1>
        <Heading as="h3" upperCase isCentered>{content?.subtitle}</Heading>
        <Button onClick={() => setIsVideoOpen(true)} variant="CTA">
          {content?.ctaButtonText}
        </Button>
      </PageHeaderContent>
      {isVideoOpen && (
        <VideoModal
          isOpen
          onClose={() => setIsVideoOpen(false)}
          videoUrl={videoUrl}
        />
      )}
    </PageHeaderContainer>
  );
};

export const query = graphql`
  fragment HeroHeaderVideoModalFragment on ContentfulComponentHeroHeaderVideoModal {
    id
    title
    subtitle
    ctaButtonText
    videoModalContent {
      file {
        url
      }
    }
    backgroundMedia {
      id
      description
      file {
        url
        contentType
      }
    }
  }
`;

type ContentProps = {
  content: i.ComponentHeroHeaderVideoModal;
};
