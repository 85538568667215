import styled from 'styled-components';

import theme from 'styles/theme';
import { media } from 'styles/utils';

export const PricingOverviewContainer = styled.div`
  margin: 0 auto;
  padding: 0 24px;

  ${media.tablet`
    background-color: ${({ theme }) => theme.colors.gray.background};
    max-width: 1400px;
    padding: 24px;
  `}
`;

export const MembershipsColumns = styled.div`
  ${media.tablet`
    display: flex;
    grid-gap: 24px;
    margin-bottom: 24px;
  `}
`;

export const List = styled.ul`
  padding-left: 16px;
`;

export const ListItem = styled.li`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;

  ${media.tablet`
    color: ${({ theme }) => theme.colors.white};
  `}
`;

export const DesktopBenefits = styled.div`
  display: none;

  ${media.tablet`
    display: block;
  `}
`;

export const BenefitItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  margin-top: auto;
  min-height: 52px;

  &:last-child {
    border-bottom: none;
  }
`;

export const TooltipContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.white};
  cursor: pointer;
  height: 12px;
  width: 12px;
  border-radius: 12px;
  font-size: 8px;
  text-align: center;
  margin-left: 12px;
`;

// Theme prop doesn't work because content is being inserted outside of themeContext at the bottom of the dom
export const TooltipContent = styled.div`
  background-color: ${theme.colors.white};
  border-radius: 4px;
  color: ${theme.colors.gray.dark};
  padding: 8px 12px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
  margin-top: 18px;

  ${media.desktop`
    margin-top: 0;
  `}
`;
