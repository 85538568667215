import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import Instagram from 'vectors/instagram.svg';
import { Button } from 'common/interaction';
import { Text } from 'common/typography';
import { configureImageSrc } from 'services';

import { BigImage, Image, GridContainer, Profile } from './styled';

export const InstagramGrid: React.FC<InstagramGridProps> = ({ content }) => (
  <GridContainer href={`https://instagram.com/${content.instagramUsername}`} target="_blank">
    <BigImage image={configureImageSrc(content.instagramImages[0]?.file?.url)} />
    <Image image={configureImageSrc(content.instagramImages[1]?.file?.url, 350)} row={1} column={3} />
    <Image image={configureImageSrc(content.instagramImages[2]?.file?.url, 350)} row={2} column={3} />
    <Image image={configureImageSrc(content.instagramImages[3]?.file?.url, 350)} row={3} column={1} />
    <Image image={configureImageSrc(content.instagramImages[4]?.file?.url, 350)} row={3} column={2} />
    <Profile>
      <Instagram />
      <Text variant="white" $size={20} $inline>@{content.instagramUsername}</Text>
      <Button size="small">Follow me</Button>
    </Profile>
  </GridContainer>
);

export const query = graphql`
  fragment InstagramGridFragment on ContentfulComponentInstagramGrid {
    id
    instagramUsername
    instagramImages {
      file {
        url
      }
    }
  }
`;

type InstagramGridProps = {
  content: i.ComponentInstagramGrid;
};
