import styled, { css } from 'styled-components';

import { Text } from 'common/typography';

export const MobilePricingOverviewContainer = styled.div`
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.gray.dark};
`;

export const MembershipSelectContainer = styled.div<MembershipSelectContainerProps>`
  display: flex;
  grid-gap: 8px;

  ${({ headerIsFixed }) => headerIsFixed && css`
    background-color: ${({ theme }) => theme.colors.white};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 80px 24px 8px;
    z-index: 1;
  `};
`;

type MembershipSelectContainerProps = {
  headerIsFixed: boolean;
  headerHeight?: number;
};

export const MembershipBenefitsContainer = styled.div<MembershipSelectContainerProps>`
  ${({ headerIsFixed, headerHeight }) => headerIsFixed && css`
    margin-top: ${headerHeight}px;
  `};
`;

export const MobileBenefitContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const MobileBenefitTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.black};
  position: absolute;
  top: 16px;
`;

export const MobileBenefitItemContainer = styled.div`
  display: flex;
  flex: 1;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.line};
  grid-gap: 8px;
`;
