import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { TextContent } from 'common/typography';
import { ContentContainer } from 'common/layout';

import { AlignmentContainer } from './styled';

export const TextBlock: React.FC<TextBlockProps> = ({ content }) => {
  return (
    <ContentContainer hasSmallMargin>
      <AlignmentContainer $align={content.alignment}>
        <TextContent alignment={content.alignment} content={content?.body?.raw} />
      </AlignmentContainer>
    </ContentContainer>
  );
};

export const query = graphql`
  fragment TextBlockFragment on ContentfulComponentTextBlock {
    id
    alignment
    body {
      raw
    }
  }
`;

type TextBlockProps = {
  content: i.ComponentTextBlock;
};
