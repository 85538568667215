import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Heading, Text } from 'common/typography';

import { HeaderContainer, ListItem } from './styled';

export const MembershipsBlockHeader: React.FC<MembershipsBlockHeaderProps> = ({ isMobile }) => {
  const { t } = useTranslation();

  return (
    <HeaderContainer {...{ isMobile }}>
      <Heading as="h2" variant="black" margin="0" upperCase>{t('memberships')}</Heading>
      <Text variant="gray" $lineHeight="24">{t('unlimited gold for those who truly have no limits')}</Text>
      <ul>
        <ListItem>{t('gold bullet 1')}</ListItem>
        <ListItem>{t('gold bullet 2')}</ListItem>
        <ListItem>{t('gold bullet 3')}</ListItem>
        <ListItem>{t('gold bullet 4')}</ListItem>
      </ul>
    </HeaderContainer>
  );
};

type MembershipsBlockHeaderProps = {
  isMobile?: boolean;
};
