import styled from 'styled-components';

import { media } from 'styles/utils';

export const GridContainer = styled.a`
  margin: 0 0 60px;
  width: 100%;
  text-decoration: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 8px;
  padding: 0 8px;

  ${media.desktop`
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 16px;
    width: 798px;
    padding: 0;
    margin: 0 auto 120px;
  `}
`;

export const BigImage = styled.div<BigImageProps>`
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 100%;

  &:after {
    position: absolute;
    inset: 0;
    content: '';
    background: ${({ image }) => `url(${image})`};
    background-size: cover;
    background-position: center;
    transition: transform .3s ease;
  }

  ${media.desktop`
    grid-column: 1/3;
    grid-row: 1/3;
    height: auto;

    &:hover:after {
      transform: scale(1.08);
    }
  `}
`;

type BigImageProps = {
  image: string;
};

export const Image = styled(BigImage)`
  ${media.desktop<ImageProps>`
    grid-column: ${({ column }) => column};
    grid-row: ${({ row }) => row};
  `}
`;

type ImageProps = {
  column: number;
  row: number;
};

export const Profile = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 16px;
  justify-content: space-between;
  overflow: hidden;

  p {
    line-height: 25px;
    overflow-wrap: break-word;
    width: 100%;
  }

  svg {
    fill: ${({ theme }) => theme.colors.white};
    width: 32px;
    height: 32px;
  }

  ${media.desktop`
    grid-column: 3;
    grid-row: 3;
    padding: 44px 16px;
  `}
`;
