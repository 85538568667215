import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { getFilteredClasses } from 'ducks/classes/list';
import { useDispatch, useSelector } from 'services/hooks';
import { formatDay } from 'services';
import { selectTrainerFilteredClasses } from 'selectors';
import { BookingCard } from 'modules/booking';
import { Heading, Text } from 'common/typography';
import { Button } from 'common/interaction';

import { Container, Column } from './styled';

const filterKeys = {
  trainer: 'instructorId',
  studio: 'siteId',
  concept: 'classTypeId',
};

const filterUrls = {
  trainer: 'instructors',
  studio: 'studios',
  concept: 'classTypes',
};

export const FilteredSchedule: React.FC<FilteredScheduleProps> = ({ content }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const classes = useSelector(selectTrainerFilteredClasses);
  const filterType = content.trainer ? 'trainer' : content.concept ? 'concept' : 'studio';
  const zingfitId = content[filterType]?.zingfitId;

  React.useEffect(() => {
    if (zingfitId) {
      const additionalZingfitIds = filterType === 'concept'
        ? content.concept?.additionalZingfitIds || []
        : [];
      const zingfitIds = [zingfitId, ...additionalZingfitIds];

      dispatch(getFilteredClasses(zingfitIds, filterKeys[filterType]));
    }
  }, []);

  return (
    <Container>
      <Heading as="h2" upperCase variant="black">{content.title}</Heading>
      {classes && Object.entries(classes).map(([key, value]) => (
        <Column key={key} gap="8px">
          <Text $size={16} $lineHeight="24px" variant="gray">{formatDay(key)}</Text>
          <Column gap="8px">
            {value?.map((el: i.Class) => (
              <BookingCard item={el} key={el.id} />
            ))}
          </Column>
        </Column>
      ))}
      <Button
        variant="CTA"
        onClick={() => navigate(`/booking?${filterUrls[filterType]}=${zingfitId}`)}
      >
        {t('view more workouts')}
      </Button>
    </Container>
  );
};

export const query = graphql`
  fragment FilteredScheduleFragment on ContentfulComponentFilteredSchedule {
    id
    title
    trainer {
      zingfitId
    }
    studio {
      zingfitId
    }
    concept {
      zingfitId
      additionalZingfitIds
    }
  }
`;

type FilteredScheduleProps = {
  content: i.ComponentFilteredSchedule;
};
