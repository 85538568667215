import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const PromoBlockContainer  = styled.div<PromoBlockContainerProps>`
  padding: 24px;

  ${({ hidePromoBlock }) => hidePromoBlock && css`
    // SnS only wants to show the first timer promo block on mobile
    display: none;

    ${media.desktop`
      display: block;
    `}
  `};

  ${media.desktop`
    display: grid;
    grid-gap: 24px;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    grid-template-columns: repeat(8, 1fr);
  `}
`;

type PromoBlockContainerProps = {
  hidePromoBlock: boolean;
};
