import { Link } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';

import { Text } from 'common/typography';
import { media } from 'styles/utils';

export const ExtendedText = styled(Text)`
  margin: 24px 16px 0;

  ${media.desktop`
    margin: 24px 32px 0;
  `}
`;

export const TrainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${media.tablet`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.desktop`
    grid-template-columns: repeat(4, 1fr);
  `}
`;

export const Trainer = styled(Link)`
  margin-bottom: 80px;
  cursor: pointer;
  text-decoration: none;
`;

export const TrainerImage = styled.div<TrainerImageProps>`
  height: 2px;
  padding-top: 100%;
  background: ${({ src }) => `url(${src})`};
  background-size: 100%;
  background-position: center;
  transition: background-size .3s ease;
  background-size: cover;

  &:hover {
    background-size: 105%;
  }
`;

type TrainerImageProps = {
  src: string;
};
