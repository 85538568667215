import styled from 'styled-components';

import { media } from 'styles/utils';

export const ContentContainer = styled.div`
  max-width: 1152px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 0 24px 60px;

  ${media.desktop`
    padding: 0 0 60px;
  `}
`;

export const ExtendedImage = styled.img`
  max-width: 100%;
  max-height: 552px;
`;
