import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const Layout = styled.div<LayoutProps>`
  display: flex;
  flex-direction: column;
  gap: 2vh;
  padding: 24px;

  ${media.desktop`
    display: grid;
    grid-gap: 24px;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    grid-template-columns: repeat(8, 1fr);
  `}

  ${({ isContentBlock }) => isContentBlock && css`
    padding: 0;

    ${media.desktop`
      display: flex;
    `}
  `};
`;

type LayoutProps = {
  isContentBlock?: boolean;
};

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-column: 1/3;
`;
