import * as i from 'types';
import * as React from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { Button } from 'common/interaction';
import { Text } from 'common/typography';
import { triggerGtagEvent } from 'services';
import { useAuthenticationUser } from 'services/hooks';
import { useCheckoutContext } from 'modules/PricingPage';

import { MembershipStep } from '../';
import {
  MembershipSummaryContainer,
  MembershipSummaryDetails,
  MembershipSummaryDetailsOverview,
  MembershipSummaryPriceRow,
  MembershipSummaryButtonWrapper,
} from './styled';

export const MembershipSummary = ({ isUpgrade }: MembershipSummaryProps) => {
  const { navigate, originalPath } = useI18next();
  const { t } = useTranslation();
  const { selectedMembership, selectedSerie, selectedMembershipType, handleOpenModal } = useCheckoutContext();
  const { authenticated } = useAuthenticationUser();

  const handleModal = (item?: i.Serie) => {
    if (!item) return;

    if (!authenticated) {
      return navigate('/register',
        {
          state: {
            redirectUrl: originalPath,
          },
        });
    }

    // JSON Template for GTAG
    const eventPayload = {
      currency: item.price.currency,
      value: item.price.amount,
      items: [
        {
          item_id: item.id,
          item_name: item.name,
          affiliation: 'affiliation',
          coupon: 'coupon',
          discount: 'discount',
          index: 0,
          item_brand: 'item_brand',
          item_category: item.seriesType,
          item_list_id: 'item_list_id',
          item_list_name: 'item_list_name',
          item_variant: 'item_variant',
          location_id: 'location_id',
          price: item.price,
          quantity: 1,
        },
      ],
    };
    triggerGtagEvent('add_to_cart', eventPayload);
    handleOpenModal(item);
  };

  return (
    <MembershipSummaryContainer>
      <MembershipStep
        step={isUpgrade ? '' : t('STEP 3/3')}
        title={isUpgrade ? 'Check-out' : t('summary')}
        description={isUpgrade ? '' : t('Choose the right membership and term to continue to the check-out')}
      />
      <MembershipSummaryDetails>
        <MembershipSummaryDetailsOverview>
          {selectedMembership && (
            <>
              {selectedMembershipType === 'upgrade' ? (
                <MembershipSummaryPriceRow>
                  <Text>{selectedMembership?.upgradeSerie?.name}</Text>
                  <Text>€{selectedMembership?.upgradeSerie?.price?.amount}</Text>
                </MembershipSummaryPriceRow>
              ) : (
                <MembershipSummaryPriceRow>
                  {selectedMembershipType === 'annually' && (
                    <>
                      <Text>{selectedMembership?.annualSerie.name}</Text>
                      <Text>€{selectedMembership?.annualSerie?.price?.amount}</Text>
                    </>
                  )}
                  {selectedMembershipType === 'flex' && (
                    <>
                      <Text>{selectedMembership?.flexSerie.name}</Text>
                      <Text>€{selectedMembership?.flexSerie?.price?.amount}</Text>
                    </>
                  )}
                </MembershipSummaryPriceRow>
              )}
              {selectedMembershipType === 'upgrade' ? (
                <MembershipSummaryPriceRow hasBorderTop>
                  <Text variant="gray">{t('membership upgrade description')}</Text>
                </MembershipSummaryPriceRow>
              ) : (
                <MembershipSummaryPriceRow hasBorderTop>
                  <Text $weight={700}>{t('total per 4 weeks')}</Text>
                  <Text $weight={700}>€{selectedSerie?.price?.amount}</Text>
                </MembershipSummaryPriceRow>
              )}
            </>
          )}
        </MembershipSummaryDetailsOverview>
        <MembershipSummaryButtonWrapper>
          <Button
            variant="CTA"
            onClick={() => handleModal(selectedSerie)}
            size="fullWidth"
            disabled={!selectedSerie}
          >
            {selectedMembershipType === 'upgrade' ? 'Upgrade now' : t('continue')}
          </Button>
        </MembershipSummaryButtonWrapper>
      </MembershipSummaryDetails>
    </MembershipSummaryContainer>
  );
};

type MembershipSummaryProps = {
  isUpgrade?: boolean;
};
