import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { Carousel } from 'common/interaction';
import { getSlug } from 'services';

export const CarouselBlock: React.FC<CarouselBlockProps> = ({ content }) => {
  const isSmall = content?.variant === 'small';
  const body = content?.carouselBody;

  const slides = body.map((el) => {
    const sysType = el.sys.contentType.sys.id;
    const determinedTitle = sysType === 'concept' || sysType === 'componentCarrouselItem' ? el.title : el.name;
    const slug = el.slug || '';

    return {
      ...el,
      title: determinedTitle || '',
      tagline: el.tagline,
      cta: el.slug && getSlug(sysType, slug),
    };
  });

  return <Carousel {...{ isSmall, slides }} />;
};

export const query = graphql`
  fragment CarouselFragment on ContentfulComponentCarousel {
    id
    variant
    carouselBody: body {
      ... on Node {
        ... on ContentfulTypeConcept {
          sys {
            type
            contentType {
              sys {
                type
                linkType
                id
              }
            }
          }
          title
          slug
          frontImage {
            file {
              url
            }
          }
          tagline
        }
        ... on ContentfulTypeStudio {
          tagline
          sys {
            type
            contentType {
              sys {
                type
                linkType
                id
              }
            }
          }
          name
          slug
          frontImage {
            file {
              url
            }
          }
        }
        ... on ContentfulTypeTrainer {
          sys {
            type
            contentType {
              sys {
                type
                linkType
                id
              }
            }
          }
          name
          tagline
          slug
          frontImage {
            file {
              url
            }
          }
        }
        ... on ContentfulComponentCarouselItem {
          sys {
            type
            contentType {
              sys {
                type
                linkType
                id
              }
            }
          }
          title
          tagline
          slug
          frontImage {
            file {
              url
            }
          }
        }
      }
    }
    title
  }
`;

type CarouselBlockProps = {
  content: i.ComponentCarousel;
};
