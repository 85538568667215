import styled from 'styled-components';

import { media } from 'styles/utils';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.gray.light};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 24px 24px 48px 24px;
  gap: 24px;
  margin-bottom: 60px;
  padding: 24px 24px 54px;

  ${media.desktop`
    gap: 32px;
    margin-bottom: 120px;
  `}
`;

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || '4px'};
  max-width: 552px;
  width: 100%;
`;

type ColumnProps = {
  gap?: string;
};
