import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { ContentContainer } from 'common/layout';
import {
  ClassesBlock, CheckoutModal, MembershipsBlock, MembershipsBlockMobile, CheckoutProvider,
} from 'modules/PricingPage';
import { api } from 'services/api';
import { useDevice } from 'services/hooks';

export const PricingBlock: React.FC<PricingBlockProps> = ({ content }) => {
  const { isMobile } = useDevice();
  const [series, setSeries] = React.useState<i.Serie[]>([]);
  const isMemberships = series[0]?.unlimited;

  React.useEffect(() => {
    if (content.ids?.length > 0) {
      const seriePromises: Promise<i.Serie>[] = content.ids.map((seriesId) => api.get({ path: `/proxy/zingfit/series/${seriesId}`, withAuth: false })
        .then((serie: i.Serie) => {
          const price = Math.round(serie.price.amount * 1.09);
          return { ...serie, price: { amount: price, currency: serie.price.currency } };
        }));
      Promise.all(seriePromises)
        .then((zingfitSeries) => setSeries(zingfitSeries));
    }
  }, []);

  return (
    <ContentContainer>
      <CheckoutProvider>
        {!isMemberships ? (
          <ClassesBlock classes={series} isContentBlock />
        ) : isMobile ? (
          <MembershipsBlockMobile memberships={series} isContentBlock />
        ) : (
          <MembershipsBlock memberships={series}  isContentBlock />
        )}
        <CheckoutModal />
      </CheckoutProvider>
    </ContentContainer>
  );
};

export const query = graphql`
  fragment PricingBlockFragment on ContentfulComponentPricingBlock {
    id
    title
    ids
  }
`;

type PricingBlockProps = {
  content: i.ComponentPricingBlock;
};
