import styled from 'styled-components';

import { Heading, Text } from 'common/typography';
import { media } from 'styles/utils';

import { MembershipHeaderContainer } from '../MembershipHeader/styled';

export const MembershipStepContainer = styled.div`
  padding: 24px 0;

  ${media.tablet`
    padding: 24px;
    background-color: ${({ theme }) => theme.colors.black};
    border: 1px solid ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    max-width: 320px;
    width: 100%;
    border-radius: 4px;
  `}
`;

export const MembershipStepHeader = styled(MembershipHeaderContainer)`
  align-items: flex-start;
  height: min(100%, 232px);
  overflow: hidden;
`;

export const MembershipStepTitle = styled(Heading)`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;

  ${media.tablet`
    color: ${({ theme }) => theme.colors.white};
    margin: 8px 0;
    font-weight: 400;
  `}
`;

export const Step = styled(Text)`
  display: none;

  ${media.tablet`
    display: block;
  `}
`;

export const MobileStep = styled(Text)`
  margin: 8px 0 16px;

  ${media.tablet`
    display: none;
  `}
`;
