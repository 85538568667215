import styled from 'styled-components';

import { media } from 'styles/utils';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2vh;
  padding: 24px;

  ${media.desktop`
    display: grid;
    grid-gap: 24px;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    grid-template-columns: repeat(8, 1fr);
  `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-column: 1/3;
`;

export const PromoWrapper = styled.div`
  grid-column: 3/9;
`;
