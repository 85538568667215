import * as React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Counter, CountdownContainer, CounterLabel } from './styled';

export const CountdownTimer = ({ countdownDateTime }: CountdownTimerProps) => {
  const { t } = useTranslation();
  const countdownDate = dayjs(countdownDateTime);
  const [countdownSeconds, setCountdownSeconds] = React.useState(dayjs(countdownDate).diff(dayjs(), 'second'));

  React.useEffect(() => {
  // Exit early if countdown is finished
    if (countdownSeconds <= 0) {
      return;
    }

    // Set up the timer
    const timer = setInterval(() => {
      setCountdownSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Clean up the timer
    return () => clearInterval(timer);
  }, [countdownSeconds]);

  // Format the remaining time
  const formatTime = (timeInSeconds: number) => {
    if (timeInSeconds <= 0) return ({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    let delta = timeInSeconds;
    const days = Math.floor(delta / 24 / 60 / 60);
    delta -= days * 24 * 60 * 60;
    const hours = Math.floor(delta / 60 / 60) % 24;
    delta -= hours * 60 * 60;
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    const seconds = delta % 60;
    return ({
      days,
      hours: hours < 10 ? `0${hours}` : hours,
      minutes: minutes < 10 ? `0${minutes}` : minutes,
      seconds: seconds < 10 ? `0${seconds}` : seconds,
    });
  };

  const { days, hours, minutes, seconds } = formatTime(countdownSeconds);

  return (
    <CountdownContainer>
      <div>
        <Counter>{days}</Counter>
        <CounterLabel variant="white"$uppercase>{t('days')}</CounterLabel>
      </div>
      <div>
        <Counter>{hours}</Counter>
        <CounterLabel variant="white" $uppercase>{t('hours')}</CounterLabel>
      </div>
      <div>
        <Counter>{minutes}</Counter>
        <CounterLabel variant="white" $uppercase>{t('minutes')}</CounterLabel>
      </div>
      <div>
        <Counter>{seconds}</Counter>
        <CounterLabel variant="white" $uppercase>{t('seconds')}</CounterLabel>
      </div>
    </CountdownContainer>
  );
};

type CountdownTimerProps = {
  countdownDateTime: string;
};
